<template>
  <v-layout wrap>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-flex xs12 text-left pb-3 pt-6>
      <span style="font-family: poppinssemibold">REPORT</span>
    </v-flex>
    <v-progress-linear
      :color="appColor"
      rounded
      value="100"
      height="2px"
    ></v-progress-linear>
    <v-flex text-left xs12 pt-4 text-center v-if="piedata.OrganizationDetails.name">
      <span style="color: black; font-size: 25px; font-family: poppinssemibold">
        {{ piedata.OrganizationDetails.name }}</span
      >
    </v-flex>
    <v-flex xs12>
      <v-flex xs12 pt-8 md2 text-right pb-3 pt-6>
        <v-select
          item-color="#005f32"
          :items="years"
          item-text="fyear"
          item-value="_id"
          dense
          label="Year"
          v-model="year2"
        >
        </v-select>
      </v-flex>
      <v-flex xs12>
        <div v-if="series2.length > 0">
        <center>
          <apexchart
            type="pie"
            width="480"
            :options="chartOptions2"
            :series="series2"
          >
          </apexchart>
        </center>
        </div>
      </v-flex>
    </v-flex>
  </v-layout>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import axios from "axios";
// import Analytics from "./analytics";
// import GraphData from "./graphData";

export default {
  components: {
    apexchart: VueApexCharts,
    // GraphData,
  },

  watch: {
    year2() {
      this.piechartdata();
    },
  },
  data() {
    return {
      amtAchieved: 0,
      amtCommitted: 0,
      amtNotConfirmed: 0,
      organisationList: [],
      ORG: null,
      projects: [],
      piedata: [],
      // piedata1: {},
      // piedata2: {},
      // piedata3: {},
      series2: [],
      showsnackbar: false,
      project: null,
      years: [],
      orgId1: null,
      year2: null,
      flag: false,
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      dashboard: {},
      // download excel
      myData: [],

      //
      chartOptions2: {
        chart: {
          width: 480,
          type: "pie",
        },
        colors: ["#D61A3C", "#0E26B1", "#FFA500", "rgb(0, 77, 26)"],
        fill: {
          colors: ["#D61A3C", "#0E26B1", "#FFA500", "rgb(0, 77, 26)"],
        },
        labels: [
          "Amount to be Raised (Rs.)",
          "Committed but not Confirmed (Rs.)",
          "Committed and Confirmed (Rs.)",
          "Amount Received (Rs.)",
        ],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    };
  },

  computed: {
    userType() {
      return this.$store.state.userType;
    },
  },
  mounted() {
    this.piechartdata();
    this.getYear();
  },
  methods: {
    getYear() {
      this.appLoading = true;
      axios({
        url: "/fyear/getlist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.years = response.data.data;
            this.year2 = response.data.data[1]._id;

          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    piechartdata() {
      this.appLoading = true;
      this.flag = false;
      this.series2 = [];
      axios({
        method: "POST",
        url: "/dashboard/pichart",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          year2: this.year2,
          orgId1: this.$route.query.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.piedata = response.data.data;
            this.series2 = [
            this.piedata.ToBeRaised,
            this.piedata.CommittedNotConfirmed,
              this.piedata.Committed,
              this.piedata.Recived,
            ];
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>